import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import HotelBookService from "../../API/HotelBookService";
import HotelBookingList from "../../components/hotel/HotelBookingList";

const HotelBooking = () => {



    const [bookingList, setBookingList] = useState([]);

    const [fetchBookings, isLoading] = useFetching(async () => {
        const {data} = await HotelBookService.getAll(null, 0);
        setBookingList(data.content);
    });

    useEffect(() => {
        fetchBookings();
    }, []);

    return (
        <>
            <HotelBookingList bookingList={bookingList} isLoading={isLoading} />
        </>
    );
};

export default HotelBooking;