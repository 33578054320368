import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import ProductCategoryService from "../../API/ProductCategoryService";
import {Button, Image, Space, Table} from "antd";
import {useHistory} from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";

const ProductCategories = () => {

    const router = useHistory();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'ID родительской категории',
            dataIndex: 'parentId',
            key: 'parentID'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'nameKk',
            key: 'nameKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'nameEn',
            key: 'nameEn'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/merchant/categories/${record.id}`)}>Редактировать</Button>
                    <Button type="danger" onClick={() => onDelete(record)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [deleteCategory, isDeleting] = useFetching(async (id) => {
        await ProductCategoryService.delete(id);
        await fetchCategories();
    });

    const onDelete = async (category) => {
        Modal.confirm({
            title: 'Удалить категорию?',
            icon: <ExclamationCircleOutlined />,
            content: `${category.nameRu} будет удален`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return deleteCategory(category.id);
            },
            onCancel() {},
        });
    }

    const [categories, setCategories] = useState([]);

    const [fetchCategories, isLoading] = useFetching(async () => {
       const {data} = await ProductCategoryService.getAll();
       setCategories(data);
    });

    useEffect(() => {
        fetchCategories();
    }, []);

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => router.push('/merchant/categories/new')}
            >
                Добавить категорию
            </Button>
            <Table columns={columns} dataSource={categories} rowKey='id' loading={isLoading || isDeleting}/>
        </>
    );
};

export default ProductCategories;