import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import TicketTypeService from "../../API/TicketTypeService";
import {Button, Image, Space, Table} from "antd";
import {useHistory} from "react-router-dom";

const TicketTypes = () => {

    const router = useHistory();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Категория',
            key: 'categoryName',
            render: (text, record) => (
                record.categoryName.ru
            )
        },
        {
            title: 'Наименование',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Код',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Кол-во тарифов',
            key: 'imageUrl',
            render: (text, record) => (
                record.tariffs.length
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/tickets/types/${record.id}`)}>Редактировать</Button>
                </Space>
            )
        }
    ];

    const [types, setTypes] = useState([]);

    const [fetchTypes, isLoading] = useFetching(async (page, categoryId) => {
        const {data} = await TicketTypeService.getAll(page, categoryId);
        setTypes(data.content);
    });

    useEffect(() => {
        fetchTypes(0, null);
    }, []);

    return (
        <div>
            <Table columns={columns} dataSource={types} rowKey='id' loading={isLoading}/>
        </div>
    );
};

export default TicketTypes;