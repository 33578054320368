import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import UserService from "../../API/UserService";
import {Button, Descriptions, Image, PageHeader, Typography} from "antd";
import HotelBookingList from "../../components/hotel/HotelBookingList";
import HotelBookService from "../../API/HotelBookService";
import RestaurantReservationService from "../../API/RestaurantReservationService";
import RestaurantReservationList from "../../components/restaurant/RestaurantReservationList";
import OrderService from "../../API/OrderService";
import OrderList from "../../components/orders/OrderList";
import BonusService from "../../API/BonusService";
import ChangePasswordModal from "../../components/users/ChangePasswordModal";
import BonusModal from "../../components/users/BonusModal";


const UserIdPage = () => {

    const params = useParams();
    const router = useHistory();

    const [user, setUser] = useState({});

    const [loadUser] = useFetching(async (userId) => {
        const {data} = await UserService.getByUserId(userId);
        setUser(data);
    });

    const [passwordModalVisible, setPasswordModalVisible] = useState(false);
    const [bonusModalVisible, setBonusModalVisible] = useState(false);
    const [bonusOperationType, setBonusOperationType] = useState("ADD");

    const showBonusModal = (operationType) => {
        setBonusOperationType(operationType);
        setBonusModalVisible(true);
    };

    // hotels
    const [hotelBookingList, setHotelBookingList] = useState([]);

    const [fetchHotelBookings, isHotelBookingsLoading] = useFetching(async (userId) => {
        const {data} = await HotelBookService.getAll(userId, 0);
        setHotelBookingList(data.content);
    });

    // restaurants
    const [restaurantReservations, setRestaurantReservations] = useState([]);

    const [fetchRestaurantReservations, isRestaurantReservationsLoading] = useFetching(async (userId) => {
        const {data} = await RestaurantReservationService.getAll(userId, 0);
        setRestaurantReservations(data.content);
    });

    // orders
    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
    });

    const [fetchOrders, isLoading] = useFetching(async (page, size, userId) => {
        const {data} = await OrderService.getAll(page, size, userId);
        setOrders(data.items);
        setPagination({
            current: data.page + 1,
            pageSize: pagination.pageSize,
            total: data.totalItems
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize, params.id);
    }

    // bonuses
    const [bonuses, setBonuses] = useState({});
    const [fetchBonuses, isBonusesLoading] = useFetching(async (userId) => {
        const {data} = await BonusService.getUserBonuses(userId);
        setBonuses(data);
    });

    useEffect(() => {
        loadUser(params.id);
        fetchOrders(pagination.current, pagination.pageSize, params.id);
        // fetchHotelBookings(params.id);
        // fetchRestaurantReservations(params.id);
        // fetchBonuses(params.id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Информация о пользователе"
                extra={[
                    <Button onClick={() => showBonusModal('WITHDRAW')}>Снять бонусы</Button>,
                    <Button onClick={() => showBonusModal('ADD')}>Начилсить бонусы</Button>,
                    // <Button type="primary" onClick={() => setPasswordModalVisible(true)}>Изменить пароль</Button>
                ]}
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Фото"><Image
                        width={150}
                        src={user.imageUrl}
                    /></Descriptions.Item>
                    <Descriptions.Item label="Имя">{user.firstName}</Descriptions.Item>
                    <Descriptions.Item label="Фамилия">{user.lastName}</Descriptions.Item>
                    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
                    <Descriptions.Item
                        label="Дата рождения">{user.birthdate ? user.birthdate : 'Не указано'}</Descriptions.Item>
                    <Descriptions.Item label="Кол-во бонусов">{user.availableBonuses}</Descriptions.Item>
                    <Descriptions.Item label="Дата обновления">{user.updatedDate}</Descriptions.Item>
                </Descriptions>

                <Typography.Title level={4} style={{marginTop: 32}}>Заказы</Typography.Title>
                <OrderList orders={orders}
                           isLoading={isLoading}
                           pagination={pagination}
                           handleTableChange={handleTableChange}
                />

                {/*<Typography.Title level={4} style={{marginTop: 32}}>Бронь отелей</Typography.Title>*/}
                {/*<HotelBookingList bookingList={hotelBookingList} isLoading={isHotelBookingsLoading} />*/}

                {/*<Typography.Title level={4} style={{marginTop: 32}}>Бронь ресторанов</Typography.Title>*/}
                {/*<RestaurantReservationList reservations={restaurantReservations}*/}
                {/*                           isLoading={isRestaurantReservationsLoading}/>*/}
            </div>
            <ChangePasswordModal
                user={user}
                visible={passwordModalVisible}
                setVisible={setPasswordModalVisible}
            />
            <BonusModal
                user={user}
                operationType={bonusOperationType}
                visible={bonusModalVisible}
                setVisible={setBonusModalVisible}
                />

        </div>
    );
};

export default UserIdPage;