import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import NotificationService from "../../API/NotificationService";
import {useHistory, useParams} from "react-router-dom";
import {AutoComplete, Button, Col, Form, Input, message, PageHeader, Radio, Row, Select, Spin} from "antd";
import UploadImage from "../../components/UI/UploadImage";
import {Option} from "antd/es/mentions";
import ContentService from "../../API/ContentService";

const NotificationIdPage = () => {

    const params = useParams();
    const router = useHistory();
    const [form] = Form.useForm();

    const [topic, setTopic] = useState('ru');
    const [content, setContent] = useState(null);
    const [contentOptions, setContentOptions] = useState([]);
    const [fetchContent, isContentLoading] = useFetching(async (searchTerm) => {
        const {data} = await ContentService.search(searchTerm, topic);
        setContentOptions(data.map(content => {
            return {
                value: `${content.model}:${content.id} - ${content.title}`,
                label: `${content.model}:${content.id} - ${content.title}`
            }
        }));
    });

    const setContentValue = (val) => {
        if (val) {
            const contentTitle = val.split(' - ')[1];
            const [contentType, contentId] = val.split(' - ')[0].split(':');
            setContent({
                contentType: contentType,
                contentId: contentId,
                contentTitle: contentTitle
            });
        } else {
            setContent(null);
        }
    };

    const handleContentSearch = async (searchTerm) => {
        if (!!searchTerm && searchTerm.length > 2) {
            await fetchContent(searchTerm);
        }
    };

    const [saveNotification, isSaving, saveErrors] = useFetching(async (values) => {
        let response;
        if (params.id === 'new') {
            response = await NotificationService.create(values);
        } else {
            response = await NotificationService.update(params.id, values);
        }

        message.success('Уведомление сохранено');
        if (params.id === 'new') {
            router.push(`/notifications/${response.data.id}`)
        }
    });

    const onSave = async (values) => {
        const notification = {
            ...values,
            topic
        }
        if (content) {
            notification['data'] = {
                contentType: content.contentType,
                contentId: content.contentId,
                contentTitle: content.contentTitle
            }
        }
        await saveNotification(notification);
    };

    const [fetchNotification, isLoading] = useFetching(async (id) => {
        const {data} = await NotificationService.getById(id);
        setTopic(data.topic);
        if (data.data && data.data['contentId']) {
            setContent({
                contentType: data.data['contentType'],
                contentId: data.data['contentId'],
                contentTitle: data.data['contentTitle']
            });
        }
        form.setFieldsValue({...data});
    });

    useEffect(() => {
        if (params.id !== 'new') {
            fetchNotification(params.id);
        }
    }, []);

    const setImageUrl = (url) => {
        form.setFieldsValue({image: url});
    };

    const [isUploading, setIsUploading] = useState(false);

    return (
        <div>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Уведомление"
            />
            <Spin spinning={isLoading || isSaving}>
                <Form
                    form={form}
                    onFinish={onSave}
                    layout="vertical"
                    style={{background: '#fff', padding: 16}}
                >
                    <Form.Item>
                        <Button type="primary" disabled={isUploading} htmlType="submit">Сохранить</Button>
                    </Form.Item>
                    <Row gutter={32}>
                        <Col span={12}>
                            <Form.Item label="Заголовок" name="title" rules={[{required: true}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Язык">
                                <Select
                                    placeholder="Выберите язык"
                                    value={topic}
                                    onChange={(val) => setTopic(val)}
                                >
                                    <Option value="ru">Русский</Option>
                                    <Option value="kk">Казахский</Option>
                                    <Option value="en">Английский</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Контент">
                                <AutoComplete
                                    allowClear={true}
                                    options={contentOptions}
                                    loading={isContentLoading}
                                    style={{width: '100%'}}
                                    size="large"
                                    dropdownMatchSelectWidth={900}
                                    onSelect={(value) => setContentValue(value)}
                                    onClear={() => setContent(null)}
                                    onSearch={handleContentSearch}
                                    placeholder='Поиск контенту'
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Ссылка на изображение"
                                name="image"
                                rules={[
                                    {
                                        type: 'url',
                                        warningOnly: true,
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item>
                                <UploadImage setImageUrl={setImageUrl} setIsUploading={setIsUploading}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item label="Текст" name="body" rules={[{required: true}]}>
                        <Input.TextArea rows={4}/>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    );
};

export default NotificationIdPage;