import axios from "axios";

export default class ContentService {
    static async findOne(entityType, entityId) {
        return await axios.get(`/content-service/${entityType}s/${entityId}`);
    }

    static async search(text, locale) {
        return await axios.get(`/content-service/search?text=${text}&locale=${locale}`);
    }
}