import {useState} from "react";

export const useFetching = (callback) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');

    const fetching = async (...args) => {
        try {
            setError('');
            setIsLoading(true)
            await callback(...args)
        } catch (e) {
            if (e?.response?.data?.message) {
                setError(e?.response?.data?.message);
            } else {
                setError(e?.response?.data?.error)
            }
        } finally {
            setIsLoading(false)
        }
    }

    return [fetching, isLoading, error]
}