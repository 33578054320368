import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import NotificationService from "../../API/NotificationService";
import {Button, Image, Space, Table} from "antd";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {Link, useHistory} from "react-router-dom";

const Notifications = () => {

    const router = useHistory();

    const [notifications, setNotifications] = useState([]);

    const [fetchNotifications, isLoading] = useFetching(async () => {
       const {data} = await NotificationService.getAll();
       setNotifications(data.content);
    });

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'image',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.image}
                    />
                </Space>
            )
        },
        {
            title: 'Язык',
            dataIndex: 'topic',
            key: 'topic'
        },
        {
            title: 'Контент',
            key: 'content',
            render: (text, record) => (
                record.data && record.data.contentType ? `${record.data.contentType}:${record.data.contentId}` : '---'
            )
        },
        {
            title: 'Заголовок',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Текст',
            dataIndex: 'body',
            key: 'body'
        },
        {
            title: 'Дата отправления',
            dataIndex: 'sendDate',
            key: 'sendDate'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/notifications/${record.id}`}>Редактировать</Link>
                    <Button type="primary" onClick={() => onSend(record)}>Отправить push</Button>
                    <Button type="danger" onClick={() => onDelete(record)}>Удалить</Button>
                </Space>
            )
        }
    ];

    useEffect(() => {
        fetchNotifications();
    }, []);

    const [deleteNotification, isDeleting] = useFetching(async (id) => {
       await NotificationService.delete(id);
       await fetchNotifications();
    });

    const onDelete = async (notification) => {
        Modal.confirm({
            title: 'Удалить уведомление?',
            icon: <ExclamationCircleOutlined />,
            content: `${notification.titleRu} будет удален`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return deleteNotification(notification.id);
            },
            onCancel() {},
        });
    }

    const [sendNotification, isSending] = useFetching(async (id) => {
        await NotificationService.send(id);
        await fetchNotifications();
    });

    const onSend = async (notification) => {
        Modal.confirm({
            title: 'Отправить уведомление?',
            icon: <ExclamationCircleOutlined />,
            content: `${notification.titleRu} будет отправлен всем`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return sendNotification(notification.id);
            },
            onCancel() {},
        });
    }

    return (
        <div>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => router.push('/notifications/new')}
            >
                Добавить уведомление
            </Button>
            <Table columns={columns}
                   dataSource={notifications} rowKey='id'
                   loading={isLoading || isSending || isDeleting}
            />
        </div>
    );
};

export default Notifications;