import React, {useEffect, useState} from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";

import Sidebar from "./components/UI/Sidebar";
import {Layout, message, Spin} from "antd";
import MyHeader from "./components/UI/MyHeader";
import {AuthContext} from "./context";
import axios from "axios";
import {privateRoutes, publicRoutes} from "./router";
import UserService from "./API/UserService";

const {Content} = Layout;

const App = () => {

    const [isAuth, setIsAuth] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [user, setUser] = useState({});

    // todo extract to env
    axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URL;
    // axios.defaults.baseURL = 'http://localhost:8080';
    axios.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async function (error) {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        if (error.response.status === 401) {
            let failedToRefreshToken = false;
            if (localStorage.getItem('refreshToken')) {
                delete axios.defaults.headers.common['Authorization'];
                const {data} = await UserService.refreshToken(localStorage.getItem('refreshToken'));
                if (data.roles.indexOf('ADMIN') === -1) {
                    message.error('Нет доступа!');
                    failedToRefreshToken = true;
                } else {
                    message.error('Сессия обновлена!');
                    axios.defaults.headers.common['Authorization'] = `Bearer ${data.accessToken}`;
                    localStorage.setItem('accessToken', data.accessToken);
                    window.location.reload();
                    return Promise.reject(error);
                }
            } else {
                failedToRefreshToken = true;
            }
            if (failedToRefreshToken) {
                delete axios.defaults.headers.common['Authorization'];
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                setIsAuth(false);
                setUser({});
            }
        }
        return Promise.reject(error);
    });

    useEffect(() => {
        if (localStorage.getItem('accessToken')) {
            setIsAuth(true);
            setUser(JSON.parse(localStorage.getItem('user')));
            axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
        }
        setLoading(false);
    }, [])

    if (isLoading) {
        return <div style={{textAlign: 'center', marginTop: '49vh'}}>
            <Spin size="large" tip='Загрузка'/>
        </div>
    }

    return (
        <AuthContext.Provider value={{
            isAuth,
            setIsAuth,
            isLoading,
            user,
            setUser
        }}>
            <BrowserRouter>
                {isAuth ? <Layout style={{minHeight: '100vh'}}>
                        <Sidebar/>
                        <Layout className="site-layout">
                            <MyHeader/>
                            <Content style={{margin: '16px'}}>
                                <div className="site-layout-background">
                                    <Switch>
                                        {privateRoutes.map(route =>
                                            <Route
                                                component={route.component}
                                                path={route.path}
                                                exact={route.exact}
                                                key={route.path}
                                            />
                                        )}
                                        <Redirect to="/"/>
                                    </Switch>
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                    : <Switch>
                        {publicRoutes.map(route =>
                            <Route
                                component={route.component}
                                path={route.path}
                                exact={route.exact}
                                key={route.path}
                            />
                        )}
                        <Redirect to="/login"/>
                    </Switch>
                }
            </BrowserRouter>
        </AuthContext.Provider>
    );
}

export default App;
