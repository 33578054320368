import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import OnboardingService from "../../API/OnboardingService";
import {Button, Image, Space, Table} from "antd";
import CreateOrUpdateOnboardingModal from "../../components/content/CreateOrUpdateOnboardingModal";

const Onboarding = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Порядковый номер',
            dataIndex: 'orderNum',
            key: 'orderNum'
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'titleRu',
            key: 'titleRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'titleKk',
            key: 'titleKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'titleEn',
            key: 'titleEn'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => showCreateOrUpdateModal(record)}>Редактировать</Button>
                    <Button type="danger" onClick={() => deleteOnboarding(record.id)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [deleteOnboarding, isDeleting] = useFetching(async (id) => {
        await OnboardingService.delete(id);
        await fetchOnboardingList();
    });

    const [onboardingList, setOnboardingList] = useState([]);

    const [fetchOnboardingList, isLoading] = useFetching(async () => {
        const {data} = await OnboardingService.getAll();
        setOnboardingList(data);
    });

    useEffect(() => {
        fetchOnboardingList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [createOrUpdateModal, setCreateOrUpdateModal] = useState(false);
    const [selectedOnboarding, setSelectedOnboarding] = useState(null);

    const showCreateOrUpdateModal = (record) => {
        setSelectedOnboarding(record);
        setCreateOrUpdateModal(true);
    }

    const onCloseCreateOrUpdateModal = async () => {
        setCreateOrUpdateModal(false);
        setSelectedOnboarding(null);
        await fetchOnboardingList();
    }

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => showCreateOrUpdateModal(null)}
            >Добавить онбординг</Button>
            <Table columns={columns} dataSource={onboardingList} rowKey='id' loading={isLoading || isDeleting}/>
            <CreateOrUpdateOnboardingModal
                onClose={onCloseCreateOrUpdateModal}
                visible={createOrUpdateModal}
                model={selectedOnboarding}
            />
        </>
    );
};

export default Onboarding;