import React, {useEffect, useState} from 'react';
import {Button, Image, Space, Table} from "antd";
import {useFetching} from "../../hooks/useFetching";
import NewsService from "../../API/NewsService";
import CreateOrUpdateNewsModal from "../../components/content/CreateOrUpdateNewsModal";

const News = () => {
    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Изображение',
            key: 'imageUrl',
            render: (text, record) => (
                <Space size="middle">
                    <Image
                        width={200}
                        src={record.imageUrl}
                    />
                </Space>
            )
        },
        {
            title: 'Тип',
            key: 'type',
            render: (text, record) => (
                <div>{record.type === 'EVENT' ? 'Событие' : 'Соц. сети'}</div>
            )
        },
        {
            title: 'Показывать?',
            key: 'enabled',
            render: (text, record) => (
                <div>{record.enabled ? 'Да' : 'Нет'}</div>
            )
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'titleRu',
            key: 'titleRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'titleKk',
            key: 'titleKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'titleEn',
            key: 'titleEn'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => showCreateOrUpdateModal(record)}>Редактировать</Button>
                    <Button type="danger" onClick={() => deleteNews(record.id)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [deleteNews, isDeleting] = useFetching(async (id) => {
        await NewsService.delete(id);
        await fetchNewsList();
    });

    const [newsList, setNewsList] = useState([]);

    const [fetchNewsList, isLoading] = useFetching(async () => {
        const {data} = await NewsService.getAll();
        setNewsList(data);
    });

    useEffect(() => {
        fetchNewsList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [createOrUpdateModal, setCreateOrUpdateModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);

    const showCreateOrUpdateModal = (record) => {
        setSelectedNews(record);
        setCreateOrUpdateModal(true);
    }

    const onCloseCreateOrUpdateModal = async () => {
        setCreateOrUpdateModal(false);
        setSelectedNews(null);
        await fetchNewsList();
    }

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => showCreateOrUpdateModal(null)}
            >Добавить Новости</Button>
            <Table columns={columns} dataSource={newsList} rowKey='id' loading={isLoading || isDeleting}/>
            <CreateOrUpdateNewsModal
                onClose={onCloseCreateOrUpdateModal}
                visible={createOrUpdateModal}
                model={selectedNews}
            />
        </>
    );
};

export default News;