import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import AxessService from "../../API/AxessService";
import {Button, Descriptions, Image, PageHeader, Space, Table, Tag, Typography} from "antd";
import {CheckCircleOutlined, CloseCircleOutlined} from "@ant-design/icons";

const AxesMetaTariffIdPage = () => {

    const params = useParams();
    const router = useHistory();

    const [metaTariff, setMetaTariff] = useState({});
    const [tariffs, setTariffs] = useState([]);

    const [fetchMetaTariff, isMetaTariffLoading] = useFetching(async (id) => {
        const {data} = await AxessService.getMetaTariff(id);
        setMetaTariff(data);
    });

    const [fetchTariffs, isLoading] = useFetching(async (id) => {
        const {data} = await AxessService.getTariffsByMetaId(id);
        setTariffs(data);
    });

    useEffect(() => {
        fetchTariffs(params.id);
        fetchMetaTariff(params.id);
    }, [])

    const tariffHeaders = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Статус',
            key: 'enabled',
            render: (text, record) => {
                if (text.enabled) {
                    return <Tag icon={<CheckCircleOutlined spin/>} color="success">
                        Включен
                    </Tag>
                } else {
                    return <Tag icon={<CloseCircleOutlined/>} color="error">
                        Отключен
                    </Tag>

                }
            }
        },
        {
            title: 'Тариф',
            key: 'tariff',
            render: (text, record) => (
                text.tariff / 100.00
            )
        },
        {
            title: 'Валюта',
            dataIndex: 'currency',
            key: 'currency'
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
    ];

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title="Информация о тарифе"
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="ID">{metaTariff.id}</Descriptions.Item>
                    <Descriptions.Item label="ID для Strapi(metaTariffId)">{metaTariff.metaTariffId}</Descriptions.Item>
                    <Descriptions.Item label="Пул">{metaTariff.poolName}</Descriptions.Item>
                    <Descriptions.Item label="Тип персоны">{metaTariff.personTypeName}</Descriptions.Item>
                    <Descriptions.Item label="Тип билета">{metaTariff.ticketTypeName}</Descriptions.Item>
                    <Descriptions.Item label="Тарифов">{tariffs.length}</Descriptions.Item>
                    <Descriptions.Item label="Дата создания">{metaTariff.createdDate}</Descriptions.Item>
                    <Descriptions.Item label="Дата обновления">{metaTariff.updatedDate}</Descriptions.Item>
                </Descriptions>

                <Typography.Title level={3} style={{marginTop: 32}}>Тарифы по дням</Typography.Title>
                <Table
                    columns={tariffHeaders}
                    dataSource={tariffs}
                    rowKey='id'
                    loading={isLoading}
                    pagination={false}
                    size='small'
                />
            </div>
        </div>
    );
};

export default AxesMetaTariffIdPage;