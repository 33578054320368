import axios from "axios";

export default class OrderService {
    static async getAll(page, size, userId) {
        const params = {page: page - 1, pageSize: size};
        if (userId) {
            params.userId = userId;
        }
        return await axios.get('/order-service/admin/orders', {params});
    }

    static async getById(id) {
        return await axios.get(`/order-service/admin/orders/${id}`);
    }

    static async cancel(id) {
        return await axios.post(`/order-service/admin/orders/${id}/cancel`);
    }

    static async count() {
        return await axios.get(`/order-service/admin/orders/counts`);
    }

    static async grouped() {
        return await axios.get(`/order-service/admin/orders/grouped`);
    }
}