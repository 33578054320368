import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import {useFetching} from "../../hooks/useFetching";
import OrderService from "../../API/OrderService";
import {Button, Descriptions, Image, PageHeader, Space, Table, Tag, Typography} from "antd";
import OrderList from "../../components/orders/OrderList";
import HotelBookingList from "../../components/hotel/HotelBookingList";
import RestaurantReservationList from "../../components/restaurant/RestaurantReservationList";
import UserService from "../../API/UserService";
import {CheckCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined} from "@ant-design/icons";

const OrderIdPage = () => {

    const params = useParams();
    const router = useHistory();
    const [order, setOrder] = useState({});
    const [user, setUser] = useState({});

    const [fetchOrder, isLoading] = useFetching(async (id) => {
        const {data} = await OrderService.getById(id);
        const userResponse = await UserService.getByUserId(data.userId);
        setOrder(data);
        setUser(userResponse.data);
    });

    useEffect(() => {
        fetchOrder(params.id);
    }, []);

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Axess Meta tariff id',
            dataIndex: 'axesMetaTariffId',
            key: 'axesMetaTariffId'
        },
        {
            title: 'Axess ticket id',
            dataIndex: 'axessTicketId',
            key: 'axessTicketId'
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Type',
            dataIndex: 'type',
            key: 'type'
        },
        {
            title: 'Entity type',
            dataIndex: 'entityType',
            key: 'entityType'
        },
        {
            title: 'Entity ID',
            dataIndex: 'entityId',
            key: 'entityId'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin/>} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'RESERVED') {
                    return <Tag icon={<SyncOutlined spin/>} color="success">
                        В резерве
                    </Tag>
                } else if (record.status === 'COMPLETED') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Завершен
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<MinusCircleOutlined/>} color="default">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Цена',
            dataIndex: 'price',
            key: 'price'
        },
        {
            title: 'К оплате',
            dataIndex: 'toPay',
            key: 'toPay'
        },
        {
            title: 'skiPass',
            dataIndex: 'skiPass',
            key: 'skiPass'
        },
        {   
            title: 'PickupBox?',
            dataIndex: 'isPickupBox',
            key: 'isPickupBox'
        },
        {   
            title: 'qrData',
            dataIndex: 'qrData',
            key: 'qrData'
        },
        {
            title: 'Promocode',
            dataIndex: 'promocode',
            key: 'promocode'
        },
        {
            title: 'Insurance price',
            dataIndex: 'insurancePrice',
            key: 'insurancePrice'
        },
    ];

    return (
        <div style={{background: '#fff'}}>
            <PageHeader
                className="site-page-header"
                onBack={() => router.goBack()}
                title={`Информация о заказе #${order.orderNum}`}
                // extra={<Button type="primary">Изменить пароль</Button>}
            />
            <div style={{padding: 32}}>
                <Descriptions
                    bordered
                >
                    <Descriptions.Item label="Фото"><Image
                        width={150}
                        src={user.imageUrl}
                    /></Descriptions.Item>
                    <Descriptions.Item label="ФИО">
                        <Link to={`/users/${user.id}`}>{`${user.firstName} ${user.lastName}`}</Link>
                    </Descriptions.Item>
                    <Descriptions.Item label="Email">{user.email}</Descriptions.Item>
                    <Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>
                    <Descriptions.Item label="Статус">{order.status}</Descriptions.Item>
                    <Descriptions.Item label="Сумма заказа">{order.totalAmount}</Descriptions.Item>
                    <Descriptions.Item label="Сумма к оплате">{order.amountToPay}</Descriptions.Item>
                    <Descriptions.Item
                        label="Использовано бонусов">{order.bonusAmount ? order.bonusAmount : 0}</Descriptions.Item>
                    <Descriptions.Item label="Бонусов к начислению">{order.cashback}</Descriptions.Item>
                    <Descriptions.Item label="Скидка в сумме">{order.moneyDiscount}</Descriptions.Item>
                    <Descriptions.Item label="Скидка в процентах">{order.percentDiscount}</Descriptions.Item>
                    <Descriptions.Item label="Страховка">{order.insurance ? order.insurance : '-'}</Descriptions.Item>
                    <Descriptions.Item
                        label="Сумма за страховку">{order.insuranceAmount ? order.insuranceAmount : '-'}</Descriptions.Item>
                    {/*<Descriptions.Item label="Телефон">{user.phone}</Descriptions.Item>*/}
                    {/*<Descriptions.Item*/}
                    {/*    label="Дата рождения">{user.birthdate ? user.birthdate : 'Не указано'}</Descriptions.Item>*/}
                    {/*<Descriptions.Item label="Кол-во бонусов">{user.availableBonuses}</Descriptions.Item>*/}
                    {/*<Descriptions.Item label="Дата обновления">{user.updatedDate}</Descriptions.Item>*/}
                </Descriptions>
            </div>
            <Table columns={columns}
                   dataSource={order.orderItems}
                   rowKey='id'
                   loading={isLoading}
                   pagination={false}
            />
        </div>
    );
};

export default OrderIdPage;