import React, {useEffect, useState} from 'react';
import {useFetching} from "../../hooks/useFetching";
import RentItemService from "../../API/RentItemService";
import {Button, Image, Space, Table} from "antd";
import {useHistory} from "react-router-dom";

const RentItems = () => {

    const router = useHistory();

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Показывать?',
            key: 'type',
            render: (text, record) => record.enabled ? 'Да' : 'Нет'
        },
        {
            title: 'Кол-во',
            dataIndex: 'quantity',
            key: 'quantity'
        },
        {
            title: 'Тип',
            key: 'type',
            render: (text, record) => record.type === 'DAILY'
                ? 'Дневной'
                : record.type === 'NIGHTLY' ? 'Ночной' : 'Не указан'
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'nameRu',
            key: 'nameRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'nameKk',
            key: 'nameKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'nameEn',
            key: 'nameEn'
        },
        {
            title: 'Цена в будние дни',
            dataIndex: 'workingDayPrice',
            key: 'workingDayPrice'
        },
        {
            title: 'Цена в выходные дни',
            dataIndex: 'holidayPrice',
            key: 'holidayPrice'
        },
        {
            title: 'Доступные размеры одежд',
            key: 'clothesSizes',
            render: (text, record) => record.clothesSizes.join(', ')
        },
        {
            title: 'Доступные размеры обуви',
            key: 'shoesSizes',
            render: (text, record) => record.shoesSizes.join(', ')
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => router.push(`/rent/items/${record.id}`)}>Редактировать</Button>
                </Space>
            )
        }
    ];

    const [items, setItems] = useState([]);
    const [fetchItems, isLoading] = useFetching(async () => {
        const {data} = await RentItemService.getAll();
        setItems(data);
    });

    useEffect(() => {
        fetchItems();
    }, []);

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => router.push('/rent/items/new')}
            >
                Добавить Инвентарь
            </Button>
            <Table columns={columns}
                   dataSource={items}
                   rowKey='id'
                   loading={isLoading}
            />
        </>
    );
};

export default RentItems;