import React, {useEffect, useState} from "react";
import {useFetching} from "../../hooks/useFetching";
import FeedbackService from "../../API/FeedbackService";
import {Button, Rate, Space, Table} from "antd";
import {Link} from "react-router-dom";
import Modal from "antd/es/modal/Modal";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import ContentService from "../../API/ContentService";

const ApprovedFeedbacks = () => {

    const [feedbacks, setFeedbacks] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 50,
    });

    const [fetchFeedbacks, isLoading] = useFetching(async (page, size) => {
        const {data} = await FeedbackService.getApprovedFeedbacks(page, size);

        await Promise.all(data.items.map(async (item) => {
            try {
                const {data} = await ContentService.findOne(item.entityType, item.entityId);
                item.entityTitle = data.title;
            } catch (e) {
                item.entityTitle = '---';
            }
        }));

        setFeedbacks(data.items);
        setPagination({
            current: data.page + 1,
            pageSize: pagination.pageSize,
            total: data.totalItems
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchFeedbacks(pagination.current, pagination.pageSize, null);
    }

    useEffect(() => {
        fetchFeedbacks(pagination.current, pagination.pageSize, null);
    }, []);

    const [updateFeedback] = useFetching(async (id, action) => {
           await FeedbackService.reject(id);
       await handleTableChange(pagination);
    });

    const onAction = async (feedback, action) => {
        Modal.confirm({
            title: `'Отклонить' отзыв?`,
            icon: <ExclamationCircleOutlined />,
            content: `"${feedback.text}" будет 'отклонен'`,
            okText: 'Да',
            okType: 'danger',
            cancelText: 'Отменить',
            onOk() {
                return updateFeedback(feedback.id, action);
            },
            onCancel() {},
        });
    }

    const columns = [
        {
            title: 'entityType',
            dataIndex: 'entityType',
            key: 'entityType'
        },
        {
            title: 'entityId',
            dataIndex: 'entityId',
            key: 'entityId'
        },
        {
            title: 'Наименование',
            dataIndex: 'entityTitle',
            key: 'entityTitle'
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.user.id}`}>
                        {
                            (record.user.lastName || record.user.firstName)
                                ? `${record.user.lastName} ${record.user.firstName}`
                                : record.user.id
                        }
                    </Link>
                </Space>
            )
        },
        {
            title: 'Рейтинг',
            key: 'rating',
            width: '10%',
            render: (text, record) => (
                <Rate disabled defaultValue={record.rating} />
            )
        },
        {
            title: 'Текст',
            dataIndex: 'text',
            key: 'text'
        },
        {
            title: 'Действтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button danger onClick={() => onAction(record, 'REJECT')}>Удалить</Button>
                </Space>
            )
        }
    ];

    return (
        <div>
            <Table columns={columns}
                   dataSource={feedbacks}
                   rowKey='id'
                   loading={isLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </div>
    );
};

export default ApprovedFeedbacks;