import React, {useEffect, useState} from 'react';
import {Button, Space, Table} from "antd";
import VideoModal from "../../components/content/VideoModal";
import {useFetching} from "../../hooks/useFetching";
import LiveVideoService from "../../API/LiveVideoService";
import CreateOrUpdateLiveModal from "../../components/content/CreateOrUpdateLiveModal";

const Live = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Наименование на рус.',
            dataIndex: 'titleRu',
            key: 'titleRu'
        },
        {
            title: 'Наименование на каз.',
            dataIndex: 'titleKk',
            key: 'titleKk'
        },
        {
            title: 'Наименование на анг.',
            dataIndex: 'titleEn',
            key: 'titleEn'
        },
        {
            title: 'Video URL',
            dataIndex: 'videoUrl',
            key: 'videoUrl'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button onClick={() => showVideoModal(record)}>Открыть видео</Button>
                    <Button onClick={() => showCreateOrUpdateModal(record)}>Редактировать</Button>
                    <Button type="danger" onClick={() => deleteLiveVideo(record.id)}>Удалить</Button>
                </Space>
            )
        }
    ];

    const [deleteLiveVideo, isDeleting] = useFetching(async (id) => {
        await LiveVideoService.deleteLiveVideo(id);
        await fetchLiveVideos();
    });

    const [liveVideos, setLiveVideos] = useState([]);

    const [videoModal, setVideoModal] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');

    const showVideoModal = (record) => {
        setVideoUrl(record.videoUrl);
        setVideoModal(true);
    }

    const [createOrUpdateModal, setCreateOrUpdateModal] = useState(false);
    const [selectedLiveVideo, setSelectedLiveVideo] = useState(null);

    const showCreateOrUpdateModal = (record) => {
        setSelectedLiveVideo(record);
        setCreateOrUpdateModal(true);
    }

    const [fetchLiveVideos, isLiveVideosLoading] = useFetching(async () => {
        const {data} = await LiveVideoService.getAllLiveVideos();
        setLiveVideos(data);
    });

    const onCloseCreateOrUpdateModal = async () => {
        setCreateOrUpdateModal(false);
        setSelectedLiveVideo(null);
        await fetchLiveVideos();
    }

    useEffect(() => {
        fetchLiveVideos();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Button
                type="primary"
                style={{marginBottom: 16}}
                onClick={() => showCreateOrUpdateModal(null)}
            >Добавить Live видео</Button>
            <Table columns={columns} dataSource={liveVideos} rowKey='id' loading={isLiveVideosLoading || isDeleting}/>
            <VideoModal visible={videoModal} setVisible={setVideoModal} videoUrl={videoUrl}/>
            <CreateOrUpdateLiveModal
                onClose={onCloseCreateOrUpdateModal}
                visible={createOrUpdateModal}
                model={selectedLiveVideo}/>
        </>
    );
};

export default Live;