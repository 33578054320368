import axios from "axios";

export default class HotelBookService {
    static async getAll(userId, page) {
        const params = {
            page
        };
        if (userId) {
            params.userId = userId;
        }
        return await axios.get('/hotel-service/admin/booking', {params});
    }
}