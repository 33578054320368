import React, {useEffect, useState} from 'react';
import {Button, Space, Table, Tag} from "antd";
import {Link} from "react-router-dom";
import {CheckCircleOutlined, CloseCircleOutlined, SyncOutlined} from "@ant-design/icons";
import {useFetching} from "../../hooks/useFetching";
import RentItemOrderService from "../../API/RentItemOrderService";

const RentItemOrders = () => {

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Синхронизация с Bitrix',
            key: 'syncWithBitrix',
            render: (text, record) => (
                record.syncWithBitrix ? 'Успешно' : 'Не успешно'
            )
        },
        {
            title: 'Дата проката',
            dataIndex: 'date',
            key: 'date'
        },
        {
            title: 'Тип',
            key: 'type',
            render: (text, record) => record.rentItem.type === 'DAILY'
                ? 'Дневной'
                : record.rentItem.type === 'NIGHTLY' ? 'Ночной' : 'Не указан'
        },
        {
            title: 'Интвентарь',
            key: 'type',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/rent/items/${record.rentItem.id}`}>{record.rentItem.nameRu}</Link>
                </Space>
            )
        },
        {
            title: 'Имя',
            dataIndex: 'firstName',
            key: 'firstName'
        },
        {
            title: 'Фамилия ',
            dataIndex: 'lastName',
            key: 'lastName'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'phone',
            dataIndex: 'phone',
            key: 'phone'
        },
        {
            title: 'Рост',
            dataIndex: 'height',
            key: 'height'
        },
        {
            title: 'Вес',
            dataIndex: 'weight',
            key: 'weight'
        },
        {
            title: 'Размер одежды',
            dataIndex: 'clothesSize',
            key: 'clothesSize'
        },
        {
            title: 'Размер обуви',
            dataIndex: 'shoesSize',
            key: 'shoesSize'
        },
        {
            title: 'Сумма к оплате',
            dataIndex: 'amountToPay',
            key: 'amountToPay'
        },
        {
            title: 'Использовано бонусов',
            dataIndex: 'bonusAmount',
            key: 'bonusAmount'
        },
        {
            title: 'Итого',
            dataIndex: 'totalAmount',
            key: 'totalAmount'
        },
        {
            title: 'Кэшбек',
            dataIndex: 'cashback',
            key: 'cashback'
        },
        {
            title: 'Статус',
            key: 'status',
            render: (text, record) => {
                if (record.status === 'CREATED') {
                    return <Tag icon={<SyncOutlined spin />} color="processing">
                        В обработке
                    </Tag>
                } else if (record.status === 'COMPLETED') {
                    return <Tag icon={<CheckCircleOutlined/>} color="success">
                        Завершен
                    </Tag>
                } else if (record.status === 'CANCELLED') {
                    return <Tag icon={<CloseCircleOutlined/>} color="error">
                        Отменен
                    </Tag>
                }
            }
        },
        {
            title: 'Пользователь',
            key: 'userId',
            render: (text, record) => (
                <Space size="middle">
                    <Link to={`/users/${record.userId}`}>{record.userId}</Link>
                </Space>
            )
        },
        {
            title: 'Дата создания',
            dataIndex: 'createdDate',
            key: 'createdDate'
        },
        {
            title: 'Дата обновления',
            dataIndex: 'updatedDate',
            key: 'updatedDate'
        },
        {
            title: 'Дейсвтия',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    {/*<Button type="primary" disabled={record.status !== 'CREATED'}*/}
                    {/*        onClick={() => onCancelOrder(record)}>Отменить</Button>*/}
                </Space>
            )
        }
    ];

    const [orders, setOrders] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
    });

    const [fetchOrders, isLoading] = useFetching(async (page, size, userId) => {
        const {data} = await RentItemOrderService.getAll(page, size, userId);
        setOrders(data.content);
        setPagination({
            current: data.pageable.pageNumber + 1,
            pageSize: data.pageable.pageSize,
            total: data.totalElements
        });
    });

    const handleTableChange = async (pagination) => {
        await fetchOrders(pagination.current, pagination.pageSize, null);
    }

    useEffect(() => {
        fetchOrders(pagination.current, pagination.pageSize, null);
    }, []);

    return (
        <>
            <Table columns={columns}
                   dataSource={orders}
                   rowKey='id'
                   loading={isLoading}
                   pagination={pagination}
                   onChange={handleTableChange}
            />
        </>
    );
};

export default RentItemOrders;